//Custom styles
:root {
  --fs-xs: .75rem;
  --fs-sm: .875rem;
  --fs-md: 1.125rem;
  --fs-lg: 3rem;
  --fw-600: 600;

  --g2: .5rem;
  --g1: .25rem;
}

html,
body,
#root,
.app {
  height: 100%;
  display: flex;
  display: -ms-flexbox !important;
  flex-direction: column;
  -ms-flex-direction: column !important;
}

body {
  background-color: var(--bs-light);
}

.ml-n2 {
  margin-left: calc(var(--g2) * -1);
}
.mt-n1 {
  margin-top: calc(var(--g1) * -1);
}
.text-sm {
  font-size: var(--fs-sm) !important;
}
