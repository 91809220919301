@keyframes ldio-2itzcuhd5nf {
  0% {
    top: 58.08px;
    left: 58.08px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 10.89px;
    left: 10.89px;
    width: 94.38px;
    height: 94.38px;
    opacity: 0;
  }
}

.ldio-2itzcuhd5nf div {
  position: absolute;
  border-width: 2.42px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  animation: ldio-2itzcuhd5nf 4.347826086956522s cubic-bezier(0,0.2,0.8,1) infinite;
}

.ldio-2itzcuhd5nf div:nth-child(1) {
  border-color: #1d3f72
}

.ldio-2itzcuhd5nf div:nth-child(2) {
  border-color: #5699d2;
  animation-delay: -2.173913043478261s;
}

.loadingio-spinner-ripple-mx3xjxmjnv {
  width: 121px;
  height: 121px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-2itzcuhd5nf {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-2itzcuhd5nf div { box-sizing: content-box; }